//Get all Strategy Templates
export const getStrategyTemplates = async () => {
    let result = null
    try {
      result = await window.axios.get(`/v1/strategytemplate/`);
      let list = result.data.data;
      return list;
    } catch (err) {
      console.error(err);
      return Promise.reject(err)
    }
};

//Get a specific Strategy TEmplate
export const getStrategyTemplateById = async (id) => {
    let result = null
    try {
      result = await window.axios.get(`/v1/strategytemplate/${id}/template`);
      let list = result.data.data;
      return list;
    } catch (err) {
      console.error(err);
      return Promise.reject(err)
    }
};

//Get all Strategy Template Input Types
export const getInputTypes = async () => {
    const inputs = await window.axios.get(`/v1/strategy-input/input-types`);
    if (inputs && inputs.data) {
        return inputs.data.data;
    } else {
        return null;
    }
};

//Gets the editor settings based on Input Type
export const getEditorSettingsForInput = async (inputType) => {
    const settings = await window.axios.get(`/v1/strategytemplate/${inputType}/settings`);
    if (settings && settings.data) {
        return settings.data.data;
    } else {
        return null;
    }
};

//Gets the editor settings for specific Strategy Template
export const getEditorSettingsForStrategyTemplate = async (id) => {
    const settings = await window.axios.get(`/v1/strategytemplate/${id}/settings`);
    if (settings && settings.data) {
        return settings.data.data;
    } else {
        return null;
    }
};

//Validates the rule in the editor
export const validateRule = async (inputTypeName, strategyName, strategyDescription, frequency, editorData) => {
    let strategyTemplatePayload = {
        inputTypeName: inputTypeName,
        strategyName: strategyName,
        strategyDescription: strategyDescription,
        strategyContent: editorData,
        strategyFrequency: frequency,
    }
    try {
        let result = await window.axios.post(`/v1/strategytemplate/validate-rule`, strategyTemplatePayload)
        return result.data.data
        // TODO: read invalid data and display in the editor
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

//Save new Strategy Template
export const saveStrategyTemplate = async (strategyName, strategyDescription, inputTypeName, frequency, editorData) => {
    let strategyTemplatePayload = {
        strategyName: strategyName,
        strategyDescription: strategyDescription,
        inputTypeName: inputTypeName,
        strategyFrequency: frequency,
        strategyContent: editorData,
    }
    try {
        await window.axios.post(`/v1/strategytemplate/create`, strategyTemplatePayload)
        // TODO: read invalid data and display in the editor
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

//Update Strategy Template
export const updateStrategyTemplate = async (id, strategyName, strategyDescription, inputTypeName, frequency, editorData) => {
    let strategyTemplatePayload = {
        strategyId: id,
        strategyName: strategyName,
        strategyDescription: strategyDescription,
        inputTypeName: inputTypeName,
        strategyFrequency: frequency,
        strategyContent: editorData,
    }
    try {
        await window.axios.put(`/v1/strategytemplate/update`, strategyTemplatePayload)
        // TODO: read invalid data and display in the editor
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};


//Delete Strategy Template
export const deleteStrategyTemplate = async (template) => {
    let result = null;
    var config = {
      data: { 
        id: template.id
      }
    };
    try {
      result = await window.axios.delete(`/v1/strategytemplate/delete`, config);
      return result.data.data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
};
  
export default {
    getStrategyTemplates,
    getStrategyTemplateById,
    getInputTypes,
    getEditorSettingsForInput,
    getEditorSettingsForStrategyTemplate,
    validateRule,
    saveStrategyTemplate,
    updateStrategyTemplate,
    deleteStrategyTemplate
};