<template>    
  <validation-observer ref="obs" v-slot="{ invalid }">
    <v-form @submit.prevent="submit">
      <p class="headline pt-3">Create Strategy Template</p>
      <v-sheet elevation="2" class="pa-4">
        <v-row>
          <v-col sm="6">
            <validated-select-field
              :items="inputTypes"
              item-value="inputKey"
              item-text="typeName"
              rules="required"
              v-model="selectedInput"
              label="Strategy Trigger"
              hint="What input type should trigger this strategy?"
              @change="handleSelectInputType"
              persistent-hint
              return-object
            ></validated-select-field>
          </v-col>
          <v-col sm="6">
            <validated-select-field
              :items="frequencies"
              item-value="id"
              item-text="text"
              rules="required"
              v-model="selectedFrequency"
              label="Execution Frequency"
              hint="How often should the rule be executed?"
              persistent-hint
            ></validated-select-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="6">
            <validated-text-field
              autofocus
              v-model="strategyName"
              label="Strategy Template Name"
              rules="required"
              required
            />
          </v-col>
          <v-col sm="6">
            <validated-text-field
              v-model="strategyDescription"
              label="Strategy Template Description"
              rules="required"
            ></validated-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <v-card outlined>
              <v-card-text id="divRuleEditor" ref="divRuleEditor"></v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn type="submit" color="primary" :disabled="invalid || ruleInvalid" class="mr-5">
                Save
            </v-btn>
            <v-btn color="primary" @click="validateRule">Validate</v-btn>
            <v-btn @click="handleCancelCrud" class="ml-5">Cancel</v-btn>
          </v-col>
        </v-row>
      </v-sheet>
    </v-form>
  </validation-observer>
</template>

<style lang="scss">
    div.ceRule {
        min-height: 20vh;
    }
</style>

<script>
import ValidatedTextField from "@/components/Fields/ValidatedTextField";
import ValidatedSelectField from "@/components/Fields/ValidatedSelectField";
import api from "./_api";
import { ValidationObserver } from "vee-validate";

export default {
    components: {
        "validation-observer": ValidationObserver,
        "validated-text-field": ValidatedTextField,
        "validated-select-field": ValidatedSelectField,
    },
    data() {
        return {
            ruleInvalid: true,
            siteId: 0,
            inputTypes: [],
            ruleEditor: null,
            strategyName: "",
            frequencies: [
                { id: "EveryFiveMinutes", text: "Every 5 minutes" },
                { id: "EveryTenMinutes", text: "Every 10 minutes" },
                { id: "EveryFifteenMinutes", text: "Every 15 minutes" },
                { id: "EveryThirtyMinutes", text: "Every 30 minutes" },
                { id: "EveryHour", text: "Every hour" },
                { id: "EveryDay", text: "Every day" },
            ],
            selectedFrequency: "EveryFifteenMinutes",
            strategyDescription: "",
            selectedInput: null
        };
    },

    async created() {
        //load static codeeffects assets
        let codeEffectsScript = document.createElement("script");
        codeEffectsScript.setAttribute("src", "/js/codeeffects.editor.js");
        document.head.appendChild(codeEffectsScript);

        let cssLink1 = document.createElement("link");
        cssLink1.setAttribute("rel", "stylesheet");
        cssLink1.setAttribute("href", "/js/codeeffects.common.css");
        document.head.appendChild(cssLink1);

        let cssLink2 = document.createElement("link");
        cssLink2.setAttribute("rel", "stylesheet");
        cssLink2.setAttribute("href", "/js/codeeffects.gray.css");
        document.head.appendChild(cssLink2);

        this.inputTypes = await api.getInputTypes();
    },

    methods: {
        async submit() {
            // TODO: validation
            let valid = await this.$refs.obs.validate();
            if (!valid) {
                return;
            }
            let ruleContent = this.ruleEditor.extract();
            await api.saveStrategyTemplate(
                this.strategyName,
                this.strategyDescription,
                this.selectedInput.inputKey,
                this.selectedFrequency,
                ruleContent
            );
            this.$toast.show("Successfully created your strategy.", null, "success");
            this.$router.push({ name: "StrategyTemplatesIndex" });
        },

        async validateRule() {
            let valid = await this.$refs.obs.validate();
            if (!valid) {
                return;
            }
            let ruleContent = this.ruleEditor.extract();
            let validationResult = await api.validateRule(
                this.selectedInput.inputKey,
                this.strategyName,
                this.strategyDescription,
                this.selectedFrequency,
                ruleContent
            );

            if (validationResult.empty) {
                this.$toast.show("Rule cannot be empty.", null, "error");
                this.ruleInvalid = true;
                return;
            }

            if (!validationResult.valid) {
                this.ruleEditor.loadInvalids(validationResult.clientInvalidData);
                this.ruleInvalid = true;
            } else {
                this.$toast.show("Rule is valid and can be saved.", null, "success");
                this.ruleInvalid = false;
            }
        },

        async handleSelectInputType(input) {
            this.editorSettings = await api.getEditorSettingsForInput(
                input.inputKey
            );
            this.loadEditorSettings();
        },

        handleCancelCrud() {
            this.$router.push({ name: "StrategTemplatesIndex" });
        },

        loadEditorSettings() {
            // there's a window.$rule, so we'll assign it to a local variable to make things easier
            let $rule = window["$rule"];
            //Initialize the Rule Editor with the editor data (localized strings) from the API settings action.
            this.ruleEditor = $rule.Context.getControl("divRuleEditor");
            if (this.ruleEditor != null) this.ruleEditor.dispose();
            $rule.Context.clear();

            //Initialize the Rule Editor with the editor data (localized strings) from the API settings action.
            this.ruleEditor = $rule.init(this.editorSettings.editorData);
            this.ruleEditor.clear();
            this.ruleEditor.loadSettings(this.editorSettings.sourceData);
            if (this.editorSettings.ruleData)
                this.ruleEditor.loadRule(this.editorSettings.ruleData);
            let $re = this.ruleEditor;
            // TODO: docs for extract claim to show if it's valid, but really don't
            //       Keep an eye on their answer to my August 27, 2021 07:54 PM questions
            //       https://codeeffects.com/Doc/Business-Rule-Ajax-Api
            //       Their demo tests the rule on save
            this.ruleEditor.onBlur(function () {
                console.log(`Editor changed: ${$re.isAltered()}`);
                // console.log("Editor extract:", $re.extract());
            });
            // TODO: add a testing mode. Waiting to see how to implement test actions
            //       Wait for reply to comment August 26, 2021 05:37 PM
            //       https://codeeffects.com/Doc/Business-Rule-Execution-Type
        }
    }
}
</script>
